.review-wrapper {
	margin-top: 10px;
	display: flex;
	flex-direction: column;

	.highlight-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@include breakpoint(mobile) {
			flex-direction: column;
		}

		.item-main {
			flex-basis: 59%;

			@include breakpoint(mobile) {
				flex-basis: 100%;
				margin-bottom: 15px;
			}
		}

		.review-item {
			display: flex;
			flex-direction: column;
			border: 1px solid $color-grey-lighter;

			.review-poster {
				img {
					height: 380px;
					width: 100%;
					object-position: top;
					object-fit: cover;
				}
			}

			.review-info {
				padding: 10px;

				.item-rating-wrapper {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.item-date {
						font-size: 12px;
						color: $color-grey-font;
					}

					.item-rating {
						background: $color-rating;
						color: $color-white;
						padding: 5px 10px;
						border-radius: 5px;
					}
				}
				.item-title {
					margin-top: 15px;
					h4 {
						font-size: 20px;
					}
				}

				.item-body {
					margin-top: 15px;
					font-size: 15px;
				}
			}
		}

		.item-sub {
			flex-basis: 40%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.review-item {
				display: flex;
				flex-direction: row;

				@include breakpoint(mobile) {
					margin-bottom: 15px;
				}

				.review-poster {
					flex-basis: 45%;
					flex-shrink: 0;

					img {
						height: 240px;
						width: 100%;
						object-position: top;
						object-fit: cover;
					}
				}
			}
		}
	}

	.multi-wrapper {
		.wrapper-item {
			margin-top: 50px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;

			@include breakpoint(mobile) {
					margin-top: 10px;
			}

			&.--mid {
				.review-item {
					flex-basis: 24.4%;

					@include breakpoint(mobile) {
						flex-basis: 49%;
					}
				}
			}

			.review-item {
				flex-basis: 32.7%;
				display: flex;

				@include breakpoint(mobile) {
					flex-basis: 100%;
					margin-bottom: 15px;
				}

				a {
					display: flex;
					flex-direction: column;
					flex-basis: 100%;

					.review-poster {
						img {
							height: 220px;
							width: 100%;
							object-fit: cover;
							object-position: top;
						}
					}

					.review-info {
						background-image: linear-gradient(
							to top,
							$color-black,
							$color-transparent
						);
						color: $color-white;
						padding: 20px 15px;
						margin-top: -62px;

						.item-info-wrapper {
							display: flex;
							justify-content: space-between;
							align-items: center;

							.item-rating {
								background: $color-rating;
								color: $color-white;
								padding: 5px 10px;
								border-radius: 5px;
							}
						}
					}
				}
			}
		}
	}

	.more-btn {
		text-align: center;
		margin-top: 50px;

		a {
			font-size: 15px;
			font-weight: 500;
			padding: 11px;
			border: 1px solid $color-black;
			margin-right: 10px;
		}
	}
}



.checked {
    color: orange;
}

.rating {
    display: inline-block;
    position: relative;
    unicode-bidi: bidi-override;
    direction: rtl;
}

.current-rating {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    opacity: 0;
}

.current-rating > i {
    color: orange;
    font-style: normal;
    font-size: 20px;
}

.rating.display-only > i {
    color: #ccc;
}

.rating.display-only > .current-rating {
    direction: ltr;
    opacity: 1;
}

.rating > i {
    display: inline-block;
    position: relative;
    font-style: normal;
    color: #ccc;
    font-size: 20px;
}

.rating:not(.display-only) > i {
    cursor: pointer;
}

.rating:not(.display-only) > i:hover,
.rating:not(.display-only) > i.active,
.rating:not(.display-only) > i:hover ~ i,
.rating:not(.display-only) > i.active ~ i {
    color: transparent;
}

.rating:not(.display-only) > i:hover:before,
.rating:not(.display-only) > i.active:before,
.rating:not(.display-only) > i:hover ~ i::before,
.rating:not(.display-only) > i.active ~ i::before {
    content: "\2605";
    position: absolute;
    left: 0;
    color: #000;
}
