.misc-wrapper {
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@include breakpoint(mobile) {
		flex-direction: column;
	}

	.content-wrapper {
		flex-basis: 60%;
		padding-right: 65px;

		@include breakpoint(mobile) {
			padding-right: 0px;
		}

		p {
			line-height: 45px;
		}

		&.--full {
			flex-basis: 100%;
			padding-right: 0px;
		}
	}

	.form-wrapper {
		flex-basis: 39%;
		@include breakpoint(mobile) {
			margin-top: 15px;
		}

		form {
			display: flex;
			flex-direction: column;

			label {
				margin-bottom: 5px;
			}

			input {
				margin-bottom: 15px;
				height: 50px;
			}

			button {
				height: 50px;
			}
		}
	}
}
