.panel {
	border: 1px solid $color-grey-lighter;


	.panel-header {
		display: flex;
		padding: 5px;
		justify-content: space-between;
		text-transform: uppercase;
		align-items: center;

		h3 {
			font-size: 15px;
			color: $color-white;
		}

		a {
			color: $color-black;
		}
	}
	.panel-body {
		display: flex;
		flex-direction: column;

		ul {
			li {
				border-bottom: 1px solid $color-grey-lighter;

				a {
					color: $color-black;
					font-size: 14px;

					h4 {
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						padding: 9px;
					}

					h3 {
						padding: 9px;
					}
				}

				&:last-of-type {
					border: none;
				}
			}
		}

		.inf-wrapper {
			padding: 9px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.inf-item {
				margin-top: 9px;

				&:first-of-type {
					margin-top: 0;
				}

				.view {
					img {
						height: 184px;
					}
				}
			}

			a {
				display: block;

				.view {
					display: flex;
					flex-direction: column;

					.slider-caption {
						font-size: 14px;
						background-image: linear-gradient(
							to top,
							$color-black,
							$color-transparent
						);
						
						color: $color-white;
						margin-top: -52px;

						p {
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							padding: 20px 15px;
						}
					}
				}
			}

			.view {
				img {
					height: 190px;
					width: 100%;
					object-fit: cover;
					object-position: top;
				}
			}
		}

		.thumb-item {
			padding: 9px;
			border-bottom: 1px solid $color-grey-lighter;

			.thumb-wrapper {
				display: flex;
				flex-direction: row;

				.image-wrapper {
					flex-basis: 50%;

					img {
						height: 93px;
						width: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
				.info-wrapper {
					padding-left: 9px;
					flex-basis: 50%;
				}
			}

			&:last-of-type {
				border-bottom: none;
			}
		}
	}
}
