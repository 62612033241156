@mixin breakpoint($size) {
  @if $size == mobile {
    @media (max-width: 767px) { @content; }
  } @else if $size == tablet {
    @media (max-width: 1024px) { @content; }
  } @else if $size == desktop {
    @media (min-width: 1025px) { @content; }
  } @else {
    @media (max-width: $size) { @content; }
  }
}
