.red {
    background: $color-red;
}

.blue {
    background: $color-blue;
}

.pink {
    background: $color-pink;
}

.cyan {
    background: $color-cyan;
}

.green {
    background: $color-green;
}

.info {
    background: $color-info;
}

.mpurple {
    background: $color-mpurple;
}

.sushi {
    background: $color-sushi;
}

.gold-drop {
    background: $color-gold-drop;
}

.clay {
    background: $color-clay;
}

.matrix {
    background: $color-matrix;
}