$color-white: #ffffff;
$color-black: #000000;
$color-grey: #bbc7d0;
$color-grey-font: #676767;
$color-grey-lite: #a2a2a2;
$color-grey-lighter: #d9dada;
$color-grey-medium: #eeeeee;
$color-grey-dark: #282c34;
$color-blue-v1: #edf6fd;
$color-blue-v2: #b8daff;
$color-red: #ff3131;
$color-blue: #0071db;
$color-pink: #cf0076;
$color-cyan: #099ca3;
$color-green: #01953f;
$color-info: #01af94;
$color-mpurple: #975adb;
$color-sushi: #82a144;
$color-gold-drop: #e48100;
$color-clay: #8e6d38;
$color-matrix: #a46256;
$color-sudo: #ff9800;

$color-data-error: #b9b9b9;
$color-error-sub:  rgba(132, 125, 125, 0.75);

$color-pager: #e3e4e6;
$color-pager-active: #a8a9ab;

$color-rating: #ffb94f;

$color-transparent: rgba(255, 0, 0, 0);

