.hp-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	.hp-item {
		margin-top: 10px;
		flex-basis: 33%;
		min-width: 0; /* Hack for text-overflow elipsis */

		@include breakpoint(tablet) {
			flex-basis: 32.8%;
		}

		@include breakpoint(mobile) {
			flex-basis: 100%;
		}

		&.nest-blocks {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.panel {
				height: auto;
			}
		}
	}

	.ad-unit-wrapper {
		flex-basis: 100%;

		@include breakpoint(tablet) {
			flex-basis: 100%;
			margin-top: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}