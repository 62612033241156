header {
	.header-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;

		.burger {
			display: none;

			@include breakpoint(mobile) {
				width: 55px;
				margin-right: 40px;
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				text-align: center;
				cursor: pointer;

				> div {
					width: 30px;
					height: 3px;
					background-color: $color-black;
					margin: 3px 0;
				}
			}

			@include breakpoint(mobile) {
				margin-right: 0px;
			}

			&.active {
				position: absolute;
				top: 20px;
				z-index: 100;
				left: 20px;
				& > div {
					margin-left: 10px;
					width: 43px;

					&:nth-child(1) {
						transform: rotate(45deg);
						transform-origin: left bottom;
					}

					&:nth-child(2) {
						opacity: 0;
						margin-top: 10px;
						margin-bottom: 10px;
					}

					&:nth-child(3) {
						transform: rotate(-45deg);
						transform-origin: left top;
					}
				}
			}
		}

		.logo-wrapper {
			flex-basis: 48%;

			@include breakpoint(mobile) {
				flex-basis: 100%;
			}

			.image-wrapper {
				font-size: 0;
			}

			.date-wrapper {
				background: $color-grey-dark;
				color: $color-white;
				padding: 3px;
				text-align: center;
			}
		}

		.ad-unit-wrapper {
			flex-basis: 48%;

			&.--full {
				flex-basis: 100%;
			}

			@include breakpoint(tablet) {
				flex-basis: 100%;
				margin-top: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			@include breakpoint(mobile) {
				display: none;
			}
		}

		.nav-wrapper {
			background: $color-grey;
			line-height: 15px;
			font-size: 15px;
			padding: 10px;
			margin-top: 15px;
			display: flex;
			flex-basis: 100%;

			.primary-nav {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				flex-basis: 100%;

				.left-links,
				.right-links {
					display: flex;
					flex-direction: row;
					align-items: center;

					li {
						a {
							color: $color-black;
						}

						&.search-wrapper {
							cursor: pointer;
						}

						&.vs-hide {
							display: none;
						}

						&.seperator {
							font-size: 24px;
							padding: 20px;
							margin-bottom: 10px;
							border-bottom: 1px solid $color-grey-lite;
						}
						&.home {
							a {
								color: $color-black;
							}
							@include breakpoint(mobile) {
								display: none;
							}
						}

						&.home-mobile {
							display: none;

							a {
								color: $color-black;
							}

							@include breakpoint(mobile) {
								display: inline-flex;
								margin-left: auto;
							}
						}
					}
				}

				.right-links {
					@include breakpoint(mobile) {
						flex-basis: 100%;
					}
					li {
						margin-left: 15px;

						@include breakpoint(mobile) {
							margin-left: 25px;
						}

						&:first-of-type {
							a {
								color: $color-red;
								font-weight: bold;
							}

							@include breakpoint(mobile) {
								margin-right: 20px;
							}
						}
					}
				}

				.left-links {
					li {
						margin-right: 35px;

						&.dropdown {
							ul {
								z-index: 2;
								background: $color-grey;
								position: absolute;
								li {
									padding: 10px;
								}
							}

							&:hover {
								ul {
									display: block;

									li {
										display: block;
									}
								}
							}
						}

						@include breakpoint(mobile) {
							margin-right: 0;
						}
					}

					@include breakpoint(mobile) {
						display: none;
					}
				}
			}

			&.menu-active {
				.left-links {
					position: absolute;
					width: 100%;
					display: flex;
					flex-direction: column;
					background: $color-white;
					justify-content: center;
					z-index: 99;
					left: 0;
					top: 0;
					height: 100%;

					li {
						margin-top: 25px;
						a {
							font-size: 20px;
						}

						&.cat-drop {
							display: none;
						}

						&.vs-hide {
							display: block;
						}
					}
				}
			}
		}

		.search-container {
			display: none;
		}

		&.search-active {
			.search-container {
				display: block;
				position: absolute;
				top: 120px;
				left: calc((50vw - 50%) * -1);
				right: calc((50vw - 50%) * -1);
				background-color: $color-grey;
				z-index: 100;
				height: 75px;

				@include breakpoint(tablet) {
					top: 72px;
				}

				@include breakpoint(mobile) {
					height: 50px;
					top: 105px;
				}

				.search-wrapper {
					max-width: 1280px;
					margin: 0 auto;
					height: 100%;
					display: flex;

					.search-form {
						display: flex;
						width: 100%;

						input[type="text"] {
							background: none;
							border: none;
							height: 100%;
							font-size: 35px;
							color: $color-black;
							width: 100%;

							@include breakpoint(mobile) {
								font-size: 25px;
							}

							&:focus {
								outline: none;
								border: none;
							}
						}

						input[type="submit"] {
							height: 100%;
							border: none;
							border-right: 1px solid $color-grey-lite;
							background-color: transparent;
							padding-right: 15px;
							font-weight: bold;

							&:focus,
							&:active {
								outline: none;
							}
						}

						.search-close {
							height: 100%;
							cursor: pointer;
							padding: 0px 15px;
							display: flex;
							align-items: center;
							font-weight: bold;
						}
					}
				}
			}

			&:after {
				content: "";
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #101820;
				opacity: 0.5;
				pointer-events: none;
				z-index: 99;
			}
		}
	}

	.flash-wrapper {
		background: $color-blue-v1;
		border: 1px solid $color-blue-v2;
		padding: 10px;
		display: flex;
		flex-direction: row;
		margin-top: 10px;

		@include breakpoint(tablet) {
			display: none;
		}

		.flash-header {
			flex-basis: 15%;
		}

		.flash-container {
			min-width: 0;
			flex-basis: 85%;

			.slick-slide {
				min-height: 20px;
			}
		}
	}
}