.column-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.column-image-wrapper {
		flex-basis: 55%;
		border-right: 1px solid $color-grey-lighter;

		a {
			display: flex;
			flex-direction: row;
			margin-bottom: 9px;
			justify-content: space-between;
			border-bottom: 1px solid $color-grey-lighter;
			padding: 9px;

			&:last-of-type {
				margin-bottom: 0px;
				border-bottom: 0;
			}

			.thumb-wrapper {
				flex-basis: 45%;
				img {
					height: 100px;
					width: 100%;
					object-fit: cover;
				}
			}

			.title-wrapper {
				flex-basis: 53%;
			}
		}
	}

	.column-list-wrapper {
		flex-basis: 45%;
		ul {
			li {
				a {
					h4 {
						white-space: normal;
					}
				}
			}
		}
	}
}
