.carousel {
	position: relative;

	.carousel-inner {
		height: auto;
	}
	.carousel-item {
		height: auto !important;
		a {
			position: relative;
			display: block;

			.view {
				display: flex;
				flex-direction: column;

				img {
					height: 245px;
					width: 100%;
					object-fit: cover;
				}

				.slider-caption {
					font-size: 14px;
					background-image: linear-gradient(
						to top,
						$color-black,
						$color-transparent
					);
					
					color: $color-white;
					margin-top: -52px;

					p {
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						padding: 20px 15px;
					}

					h3 {
						padding: 20px 15px;
					}
				}

				.carousel-tag {
					position: absolute;
					top: 0px;
					padding: 5px;
					font-weight: 500;
					color: $color-black;
					text-transform: uppercase;
					display: inline-flex;
					background: $color-sudo;
					align-items: center;
					font-size: 12px;
					height: 10px;
					margin-top: 0;
				}
			}
		}
	}

	.carousel-control-next {
		position: absolute;
		top: 40%;
		right: 2%;
		cursor: pointer;
		background: rgba(255, 255, 255, 0.9);
    	padding: 5px;
		.carousel-control-next-icon {
			background: url("../svg/right-arrow.svg");
			height: 20px;
			width: 20px;
			display: block;
		}
	}

	.carousel-control-prev {
		position: absolute;
		top: 40%;
		left: 2%;
		cursor: pointer;
		background: rgba(255, 255, 255, 0.9);
    	padding: 5px;
		.carousel-control-prev-icon {
			background: url("../svg/left-arrow.svg");
			height: 20px;
			width: 20px;
			display: block;
		}
	}
}
