.political-wrapper {

	.panel {
		height: auto;
	}
	
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.main-wrapper {
		flex-basis: 69%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.pl-item {
			flex-basis: 49%;
			margin-top: 10px;

			.head-row {
				display: flex;
				flex-direction: row;

				.head-slider {
					flex-basis: 60%;

					img {
						height: 300px;
					}

				}

				.head-list {
					flex-basis: 40%;
				}

			}

			&.--full {
				flex-basis: 100%;
			}
		}
	}

	.side-wrapper {
		flex-basis: 30%;
		display: flex;
		flex-direction: column;

		.sidebar-item {
			margin-top: 10px;
		}
	}
}