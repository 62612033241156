.body-wrapper {
	max-width: 1200px;
	margin: 0 auto;
	color: $color-black;
	font-family: Open Sans, sans-serif;

	@include breakpoint(tablet) {
		margin: 0 15px;
	}
}

a {
	color: $color-black;
}

.--mt15 {
	margin-top: 15px;
}

@include breakpoint(mobile) {
	.--m-order-1 {
		order: -3;
	}

	.--m-order-2 {
		order: -2;
	}

	.--m-order-3 {
		order: -1;
	}
}
