.extra-wrapper {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;

	a {
		flex-basis: 18%;

		@include breakpoint(tablet) {
			flex-basis: 49%;
		}

		@include breakpoint(mobile) {
			margin-bottom: 5px;
		}

		&:last-of-type {
			@include breakpoint(tablet) {
				display: none;
			}
		}
	}

	.item-wrapper {
		display: flex;
		flex-direction: column;

		.item-image {
			height: 180px;
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		.item-title {
			margin-top: 15px;
			padding: 0px 10px;
			text-align: center;
			color: $color-grey-font;
		}
	}
}
