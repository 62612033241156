.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;

	li {
		padding: 5px 10px;
		color: #000;
		margin-right: 20px;
		border-radius: 5px;
		background: $color-pager;

		a {
			color: $color-black;
		}

		&.active {
			background: $color-pager-active;
		}
	}
}
