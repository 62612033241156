footer {
	display: flex;
	flex-direction: column;
	margin-top: 45px;

	.footer-row-main {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		background: $color-grey-lighter;
		padding: 9px;

		@include breakpoint(mobile) {
			flex-direction: column;
		}

		ul {

			display: flex;
			flex-direction: row;
			align-items: center;

			li {
				margin-right: 20px;

				a {
					color: $color-black;
					font-size: 17px;
				}

				&:last-of-type {
					margin-right: 0;
				} 
			}

			&.footer-nav-links {
				@include breakpoint(mobile) {
					margin-bottom: 15px;
				}
			}

			&.social-links {
				li {
					a {
						font-size: 25px;
					}
				}
			}
		}
	}

	.footer-row-sub {
		margin-top: 15px;
		color: $color-white;
		background: $color-black;
		font-size: 14px;
		padding: 9px;
		text-align: center;
		display: block;

		@include breakpoint(mobile) {
			line-height: 21px;
    		
		}
	}
}
