.article-list-wrapper {
	margin-top: 30px;

	.list-items {
		display: flex;
		flex-direction: column;

		.list-item {
			margin-bottom: 15px;

			a {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.list-image {
					flex: 0 0 30%;
					height: 180px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.list-info {
					flex: 0 0 68%;

					.list-title {
						margin-bottom: 15px;

						h3 {
							font-size: 18px;
							color: $color-black;
						}
					}

					.list-date {
						margin-bottom: 15px;
						font-size: 12px;
						color: $color-grey-font;
					}

					.list-description {
						line-height: 25px;
						color: $color-black;
						font-size: 15px;
						margin-top: 30px;
					}
				}
			}
		}
	}

	.item-extras {
		margin-top: 10px;

		.--mt {
			margin-top: 40px;
		}

		.item-header {
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid $color-black;
		}
	}
}
