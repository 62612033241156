.gallery-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 10px;

	.pod-wrapper {
		columns: 3;
		column-gap: 10px;

		@include breakpoint(mobile) {
			columns: 1;
		}

		.pod-item {
			flex-basis: 32%;
			height: 210px;

			@include breakpoint(mobile) {
				margin-bottom: 15px;
			}

			&:first-of-type,
			&:last-of-type {
				height: 410px;

				img {
					height: 410px;
				}
			}

			img {
				height: 210px;
			}

			a {
				display: block;

				.pod-content {
					display: flex;
					flex-direction: column;

					img {
						width: 100%;
						object-fit: cover;
						object-position: top;
					}

					.pod-title {
						font-size: 14px;
						background-image: linear-gradient(
							to top,
							$color-black,
							$color-transparent
						);
						padding: 20px 15px;
						color: $color-white;
						margin-top: -52px;

						h4 {
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}

	.category-wrapper {
		.single-category-wrapper {
			display: flex;
			flex-direction: column;
			margin-top: 40px;

			@include breakpoint(tablet) {
				margin-top: 0px;
			}

			.category-item {
				margin-top: 40px;

				a {
					display: block;
					margin-bottom: 10px;
					padding-bottom: 10px;
					border-bottom: 1px solid $color-grey-lighter;
				}
			}

			.single-wrapper {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				flex-wrap: wrap;

				.item-container {
					flex-basis: 33%;
					height: 300px;
					display: flex;
					flex-direction: column;

					@include breakpoint(mobile) {
						flex-basis: 100%;
						margin-bottom: 15px;
					}

					a {
						border-bottom: none;
					}

					.single-item-wrapper {
						display: flex;
						flex-direction: column;

						img {
							height: 300px;
							width: 100%;
							object-fit: cover;
							object-position: top;
						}

						.item-title {
							font-size: 14px;
							background-image: linear-gradient(
								to top,
								$color-black,
								$color-transparent
							);
							padding: 20px 15px;
							color: $color-white;
							margin-top: -52px;

							h4 {
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
						}
					}
				}
			}
		}
	}
}

.react-images__track {

	@include breakpoint(mobile) {
		height: 100%;
	}
	
}
