.article-wrapper {
	margin-top: 10px;
	display: flex;
	flex-direction: column;

	@include breakpoint(mobile) {
		margin-top: 0px;
	}

	.item-headers {
		padding: 20px 125px 50px 125px;
		text-align: center;
		border-bottom: 1px solid $color-black;

		@include breakpoint(tablet) {
			padding: 10px;
		}

		@include breakpoint(mobile) {
			order: -1;
		}

		.item-title {
			h1 {
				font-size: 45px;
				line-height: 55px;

				@include breakpoint(tablet) {
					font-size: 30px;
				}

				@include breakpoint(mobile) {
					font-size: 22px;
					line-height: 30px;
					font-weight: bold;
				}
			}

			@include breakpoint(mobile) {
				order: -1;
			}
		}
	}

	.item-content {
		margin-top: 50px;

		@include breakpoint(mobile) {
			margin-top: 20px;
			font-size: 14px;
		}

		.item-misc-info {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			@include breakpoint(mobile) {
				justify-content: left;
			}

			.item-date {
				margin-left: 5px;
			}

			.item-share {
				flex-basis: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-top: 20px;
				justify-content: center;

				@include breakpoint(mobile) {
					justify-content: left;
				}

				a {
					color: $color-black;
					font-size: 30px;
					padding: 0px 25px;

					@include breakpoint(mobile) {
						font-size: 20px;
						padding: 0px 5px;
					}
				}
			}
		}
	}

	.item-body {
		margin-top: 50px;
		line-height: 25px;

		@include breakpoint(mobile) {
			margin-top: 15px;
			order: -1;
		}

		img {
			@include breakpoint(mobile) {
				height: auto !important;
			}
		}
	}

	.item-featured-iamge {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 450px;
		margin-top: 30px;

		@include breakpoint(mobile) {
			height: 215px;
			margin-top: 15px;
			order: -1;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top;
		}
	}

	.item-tags {
		margin-top: 30px;

		ul {
			li {
				display: inline-flex;
				font-size: 15px;
				font-weight: 500;
				padding: 11px;
				border: 1px solid $color-black;
				margin-right: 10px;

				@include breakpoint(tablet) {
					margin-top: 10px;
				}

				a {
					color: $color-black;
				}
			}
		}
	}

	.item-body-wrapper {
		margin-top: 50px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.review-info {
			flex-basis: 40%;

			@include breakpoint(mobile) {
				flex-basis: 100%;
			}

			.genre-list {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				li {
					margin-left: 10px;

					border: 1px solid $color-black;
					padding: 10px;
					border-radius: 25px;

					&:first-of-type {
						margin-left: 0px;
					}
				}
			}
		}

		.review-body {
			flex-basis: 59%;
			line-height: 25px;

			@include breakpoint(mobile) {
				flex-basis: 100%;
				margin-top: 15px;
			}
		}
	}
	.review-main {
		margin-top: 50px;
		line-height: 25px;
	}

	.item-extras {
		margin-top: 10px;

		.ad-unit-wrapper.--full {
			margin-top: 30px;
		}

		.--mt {
			margin-top: 40px;
		}

		.item-header {
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid $color-black;
		}
	}
}